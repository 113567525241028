<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-group :label="$t('name')">
                    <b-input-group prepend="TR">
                        <div class="label-as-input">{{ isNotNullValue(form.name) }}</div>
                    </b-input-group>
                    <b-input-group class="mt-2" prepend="EN">
                        <div class="label-as-input">{{ isNotNullValue(form.name_en) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    //Service
    import ParameterService from "@/services/ParameterService";

    export default {
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                form: {
                    code: null,
                    name: null,
                    name_en: null,
                }
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                ParameterService.get(id)
                                .then((response) => {
                                    this.form = response.data.data;
                                })
                                .catch((error) => {
                                    this.showErrors(error)
                                })
            },

        }
    }
</script>
