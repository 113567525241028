<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-form-group class="col-12" :label="$t('name')">
                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                        <b-input-group class="mb-3" prepend="TR">
                            <b-form-input v-model="form.name" :class="errors[0] ? 'is-invalid' : ''">
                            </b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"></b-form-invalid-feedback>
                    </ValidationProvider>
                    <ValidationProvider name="name_en" v-slot="{ valid, errors }">
                        <b-input-group prepend="EN">
                            <b-form-input v-model="form.name_en" :class="errors[0] ? 'is-invalid' : ''">
                            </b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </ValidationProvider>
                </b-form-group>
                <div class="col-12">
                    <ValidationProvider name="code" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('code')">
                            <b-form-input :validate-error="errors[0]" v-model="form.code">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </b-row>
        </ValidationObserver>

        <div class="col-12 mt-3 d-flex">
            <b-button @click="createForm" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
//Other
import { ValidationProvider, ValidationObserver } from "vee-validate"
//Service
import ParameterService from "@/services/ParameterService";

export default {
    components: {
        ValidationProvider, ValidationObserver,
    },
    data() {
        return {
            formLoading: false,
            form: {
                code: null,
                name: null,
                name_en: null,
            }
        }
    },
    methods: {
        async createForm() {
            if (this.checkPermission('parameter_store')) {
                const isValid = await this.$refs.formModalValidate.validate()
                if (isValid) {
                    ParameterService.store(this.form)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.$emit("createFormSuccess")
                        })
                        .catch((error) => {
                            this.showErrors(error, this.$refs.formModalValidate)
                        });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        }
    }
}
</script>
